<template>
  <v-card flat>
    <v-card-text>
      <div id="MfrMarketSharePieChartWatchList">
        <apex-chart
          :key="chartId"
          height="400"
          :options="MfrMarketSharePieChartWatchListOptions"
          :series="data.series"
        ></apex-chart>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiRefresh } from '@mdi/js'
import themeConfig from '@themeConfig'
import { useDataStore } from '@/stores/Data'
import { currencyConvert, getMfrNames, getMfrColors } from '@/util/functions'
import { watchEffect } from 'vue'

export default {
    name: 'MfrMarketSharePieChartWatchList',
    setup() {
        const themeColors = themeConfig.themes.light
        const dataStore = useDataStore()

        return {
            getMfrColors,
            getMfrNames,
            themeColors,
            currencyConvert,
            dataStore,
            icons: {
                mdiDotsVertical,
                mdiRefresh,
            },
        }
    },
    data() {
        return {
            MfrMarketSharePieChartWatchListOptions: {
                title: {
                    text: 'Manufacturer Market Share',
                    style: {
                        fontSize: '16px',
                        fontWeight: 'bold',
                        color: this.themeColors.primary,
                    },
                },
                legend: { show: false, position: 'bottom' },
                dataLabels: {
                    enabled: true,
                    formatter(value) { return `%${parseInt(value * 100, 10) / 100}` },
                },
                tooltip: {
                    y: { formatter(value) { return currencyConvert(value) } },
                },
                plotOptions: {
                    pie: {
                        startAngle: 0,
                        endAngle: 360,
                        expandOnClick: true,
                        dataLabels: { offset: 0, minAngleToShowLabel: 10 },
                        donut: {
                            size: '70%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '6px',
                                    color: this.themeColors.primary,
                                    formatter: getMfrNames,
                                },
                                value: {
                                    show: true,
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    offsetY: 16,
                                    formatter: currencyConvert,
                                },
                                total: {
                                    show: true,
                                    showAlways: false,
                                    label: 'Total',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    color: this.themeColors.primary,
                                    formatter: w => currencyConvert(w.globals.seriesTotals.reduce((a, b) => a + b, 0)),
                                },
                            },
                        },
                    },
                },
                chart: {
                    width: '100%',
                    id: 'MfrMarketSharePieChartWatchList',
                    animations: {
                        enabled: true,
                        easing: 'easeinout',
                        speed: 800,
                        animateGradually: { enabled: true, delay: 150 },
                        dynamicAnimation: { enabled: true, speed: 350 },
                    },
                    toolbar: {
                        show: true,
                        tools: {
                            download: true, selection: true, zoom: true, zoomin: true, zoomout: true, pan: true, reset: true
                        },
                        export: {
                            csv: {
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter: timestamp => new Date(timestamp).toDateString(),
                            },
                            svg: {},
                            png: {},
                        },
                        autoSelected: 'zoom',
                    },
                    brush: { enabled: false, autoScaleYaxis: false },
                    type: 'donut',
                    sparkline: { enabled: false },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {},
                    },
                },
                noData: {
                    text: 'No Data',
                    align: 'center',
                    verticalAlign: 'middle',
                    style: {
                        color: this.themeColors.primary,
                        fontSize: '20px',
                    },
                },
                states: {
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'darken',
                            value: 0.35,
                        },
                    },
                },
            },

            data: [],
            series: [],
            refresh: false,
            chartId: 0,
            test: []
        }
    },
    mounted() {
        watchEffect(() => {
            if (this.dataStore && this.dataStore.chartData && this.dataStore.chartData.MfrMarketSharePieChartWatchList) {
                this.data = this.dataStore.chartData.MfrMarketSharePieChartWatchList.value
                this.MfrMarketSharePieChartWatchListOptions = {
                    ...this.MfrMarketSharePieChartWatchListOptions,
                    labels: this.data.labels,
                    colors: this.getMfrColors(this.data.labels)
                }
            }
        })
    },
    methods: {

    },
}
</script>

<style>
.apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-label, .apexcharts-canvas .apexcharts-pie .apexcharts-datalabel-value {
    font-size: 1.0rem !important;
}
</style>
